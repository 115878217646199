.chat-controls-container {
  position: relative;
  padding: 0.75rem 1rem;
  background: var(--bg-primary);
  border-top: 1px solid var(--border-light);
  z-index: 100;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.03);
}

.chat-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  max-width: 720px;
  margin: 0 auto;
  background: var(--bg-secondary);
  padding: 0.5rem 0.75rem;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.chat-controls:focus-within {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: translateY(-1px);
}

.input-container {
  flex: 1;
  position: relative;
}

.chat-input {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  background: transparent;
  color: var(--text-primary);
  outline: none;
  font-family: inherit;
}

.chat-input::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

.action-button {
  width: 38px;
  height: 38px;
  padding: 0;
  border: none;
  background: transparent;
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:hover {
  background: var(--bg-hover-light);
  color: #026440;
}

.action-button svg {
  width: 20px;
  height: 20px;
}

.send-button {
  width: 38px;
  height: 38px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: linear-gradient(145deg, #026440, #72da37);
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.send-button:hover:not(:disabled) {
  transform: scale(1.05);
  background: linear-gradient(145deg, #72da37, #026440);
}

.send-button:disabled {
  background: var(--text-secondary);
  cursor: not-allowed;
  opacity: 0.3;
  box-shadow: none;
}

.send-button svg {
  width: 16px;
  height: 16px;
}

.emoji-picker {
  position: absolute;
  bottom: 100%;
  left: 1rem;
  background: var(--bg-primary-light);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 0.75rem;
  width: 320px;
  overflow: hidden;
  animation: slideUp 0.2s ease;
  border: 1px solid var(--border-light);
}

.emoji-picker-header {
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: var(--text-primary-light);
  border-bottom: 1px solid var(--border-light);
  background: var(--bg-secondary-light);
}

.emoji-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.5rem;
  padding: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.emoji-button {
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emoji-button:hover {
  background: var(--bg-hover-light);
  transform: scale(1.2);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Extras Menu */
.extras-menu {
  position: absolute;
  bottom: 100%;
  left: 4rem;
  background: var(--bg-primary-light);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  padding: 0.5rem;
  z-index: 100;
  display: flex;
  gap: 0.5rem;
  border: 1px solid var(--border-light);
  animation: slideUp 0.2s ease;
}

.extras-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background: var(--bg-secondary-light);
  border: none;
  border-radius: 8px;
  padding: 0.75rem;
  color: var(--text-secondary-light);
  cursor: pointer;
  transition: all 0.2s ease;
  width: 4rem;
}

.extras-button:hover {
  background: var(--bg-hover-light);
  color: var(--primary-color);
}

.extras-button svg {
  font-size: 1.25rem;
}

.extras-button span {
  font-size: 0.75rem;
  font-weight: 500;
}

/* Typing Status */
.typing-status {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--accent-color);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 0.9; }
  100% { opacity: 0.6; }
}

/* Send Button Animation */
.send-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  animation: spin 0.8s linear infinite;
}

.send-button.disabled {
  background: var(--text-secondary-light);
  opacity: 0.4;
  cursor: not-allowed;
}

/* Character Count */
.character-count {
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  font-size: 0.75rem;
  color: var(--text-secondary-light);
  pointer-events: none;
  transition: opacity 0.2s ease;
}

/* Input Container Modifications */
.chat-input:disabled {
  background: rgba(0,0,0,0.02);
  color: var(--text-secondary-light);
  cursor: not-allowed;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .chat-controls-container {
    background: var(--bg-dark);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .chat-controls {
    background: rgba(42, 42, 42, 0.8);
  }

  .chat-input {
    color: var(--text-primary-dark);
  }

  .chat-input::placeholder {
    color: var(--text-secondary-dark);
  }

  .action-button {
    color: var(--text-secondary-dark);
  }

  .action-button:hover {
    background: rgba(114, 218, 55, 0.2);
    color: #72da37;
  }

  .emoji-picker {
    background: var(--bg-primary-dark);
    border-color: var(--border-dark);
  }

  .emoji-picker-header {
    color: var(--text-primary-dark);
    border-color: var(--border-dark);
    background: var(--bg-secondary-dark);
  }

  .emoji-button:hover {
    background: var(--bg-hover-dark);
  }

  .extras-menu {
    background: var(--bg-primary-dark);
    border-color: var(--border-dark);
  }
  
  .extras-button {
    background: var(--bg-secondary-dark);
    color: var(--text-secondary-dark);
  }
  
  .extras-button:hover {
    background: var(--bg-hover-dark);
  }
  
  .chat-input:disabled {
    background: rgba(255,255,255,0.05);
  }
  
  .character-count {
    color: var(--text-secondary-dark);
  }

  .send-button {
    background: linear-gradient(145deg, #026440, #72da37);
  }
  
  .send-button:hover:not(:disabled) {
    background: linear-gradient(145deg, #72da37, #026440);
  }
}

/* Mobile Responsive Improvements */
@media (max-width: 768px) {
  .chat-controls-container {
    padding: 0.5rem;
  }
  
  .chat-controls {
    gap: 0.4rem;
    padding: 0.4rem 0.6rem;
    border-radius: 20px;
  }
  
  .chat-input {
    font-size: 0.95rem;
    padding: 0.5rem 0.6rem;
  }
  
  .action-button {
    width: 34px;
    height: 34px;
  }
  
  .action-button svg {
    width: 18px;
    height: 18px;
  }
  
  .send-button {
    width: 36px;
    height: 36px;
  }
  
  .extras-menu {
    left: 0;
    width: 100%;
    justify-content: space-around;
    padding: 0.5rem;
  }
  
  .emoji-picker {
    left: 0;
    width: 100%;
    height: 280px;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .chat-controls-container {
    padding: 0.35rem;
  }
  
  .chat-controls {
    gap: 0.25rem;
    padding: 0.3rem 0.5rem;
    border-radius: 18px;
  }
  
  .chat-input {
    font-size: 0.9rem;
    padding: 0.4rem 0.5rem;
  }
  
  .action-button {
    width: 32px;
    height: 32px;
  }
  
  .action-button svg {
    width: 16px;
    height: 16px;
  }
  
  .send-button {
    width: 32px;
    height: 32px;
  }
  
  .send-button svg {
    width: 14px;
    height: 14px;
  }
  
  .extras-menu {
    padding: 0.4rem;
  }
  
  .extras-button {
    padding: 0.4rem;
    width: 3.5rem;
    font-size: 0.8rem;
  }
  
  .emoji-picker {
    height: 250px;
  }
  
  .character-count {
    font-size: 0.7rem;
    bottom: 0.35rem;
    right: 0.75rem;
  }
}

/* Landscape orientation for mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .chat-controls-container {
    padding: 0.35rem 0.75rem;
  }
  
  .emoji-picker {
    height: 200px;
    bottom: 110%;
  }
  
  .extras-menu {
    bottom: 110%;
  }
}

/* Touch-friendly improvements */
@media (pointer: coarse) {
  .action-button,
  .send-button,
  .emoji-button,
  .extras-button,
  .category-button {
    min-height: 44px;
    min-width: 44px;
  }
  
  .character-count {
    bottom: 0.75rem;
  }
}

/* Fix for iOS devices - prevent zoom on input focus */
@supports (-webkit-touch-callout: none) {
  .chat-input {
    font-size: 16px;
  }
  
  input, select, textarea {
    font-size: 16px;
  }
}
