.notifications-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 320px;
  width: 100%;
}

.notification {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background-color: var(--bg-primary-light);
  box-shadow: var(--shadow-light);
  color: var(--text-primary-light);
  border-left: 4px solid;
  animation: slideIn 0.3s ease forwards;
}

.notification-close {
  background: transparent;
  border: none;
  color: var(--text-secondary-light);
  cursor: pointer;
  font-size: 0.85rem;
  margin-left: auto;
  padding: 0.25rem;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.notification-close:hover {
  opacity: 1;
}

.notification-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.75rem;
  font-size: 0.9rem;
  flex-shrink: 0;
}

.notification-message {
  font-size: 0.9rem;
  line-height: 1.4;
  flex: 1;
}

/* Success notification */
.notification-success {
  border-left-color: var(--success-color);
}

.notification-success .notification-icon {
  background-color: rgba(46, 204, 113, 0.1);
  color: var(--success-color);
}

/* Error notification */
.notification-error {
  border-left-color: var(--error-color);
}

.notification-error .notification-icon {
  background-color: rgba(239, 68, 68, 0.1);
  color: var(--error-color);
}

/* Info notification */
.notification-info {
  border-left-color: var(--primary-color);
}

.notification-info .notification-icon {
  background-color: rgba(74, 125, 255, 0.1);
  color: var(--primary-color);
}

/* Warning notification */
.notification-warning {
  border-left-color: var(--warning-color);
}

.notification-warning .notification-icon {
  background-color: rgba(245, 158, 11, 0.1);
  color: var(--warning-color);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(1rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .notification {
    background-color: var(--bg-primary-dark);
    box-shadow: var(--shadow-dark);
    color: var(--text-primary-dark);
  }
  
  .notification-close {
    color: var(--text-secondary-dark);
  }
}

/* Mobile Responsive Enhancements */
@media (max-width: 768px) {
  .notifications-container {
    top: 0.5rem;
    right: 0.5rem;
    max-width: calc(100% - 1rem);
    width: auto;
  }
  
  .notification {
    padding: 0.6rem 0.75rem;
    font-size: 0.9rem;
    border-radius: 6px;
  }
  
  .notification-icon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
  }
  
  .notification-message {
    font-size: 0.85rem;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .notifications-container {
    top: 0.35rem;
    right: 0.35rem;
    gap: 0.5rem;
  }
  
  .notification {
    padding: 0.5rem 0.7rem;
    font-size: 0.85rem;
  }
  
  .notification-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.4rem;
  }
  
  .notification-message {
    font-size: 0.8rem;
  }
  
  .notification-close {
    padding: 0.15rem;
  }
}

/* Landscape orientation for mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .notifications-container {
    max-width: 260px;
  }
}

/* High-DPI screens (Retina displays) */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .notification {
    border-left-width: 3px;
  }
}

/* Stacked notifications for smaller screens */
@media (max-width: 360px) {
  .notifications-container {
    max-width: 100%;
    width: calc(100% - 0.7rem);
  }
} 