.emoji-picker {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 320px;
  height: 350px;
  background-color: var(--bg-primary-light);
  border-radius: 12px;
  box-shadow: var(--shadow-light);
  border: 1px solid var(--border-light);
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  animation: scaleIn 0.2s ease;
  overflow: hidden;
}

.emoji-picker-header {
  padding: 12px;
  border-bottom: 1px solid var(--border-light);
}

.emoji-search {
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--border-light);
  background-color: var(--bg-secondary-light);
  color: var(--text-primary-light);
  font-size: 14px;
  outline: none;
  transition: all 0.2s ease;
}

.emoji-search:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(74, 125, 255, 0.1);
}

.emoji-grid {
  flex: 1;
  overflow-y: auto;
  padding: 12px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 4px;
  scrollbar-width: thin;
  scrollbar-color: var(--text-secondary-light) transparent;
}

.emoji-grid::-webkit-scrollbar {
  width: 6px;
}

.emoji-grid::-webkit-scrollbar-track {
  background: transparent;
}

.emoji-grid::-webkit-scrollbar-thumb {
  background-color: var(--text-secondary-light);
  border-radius: 6px;
}

.emoji-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.1s ease;
  user-select: none;
}

.emoji-button:hover {
  background-color: var(--bg-hover-light);
  transform: scale(1.1);
}

.emoji-categories {
  display: flex;
  padding: 8px;
  border-top: 1px solid var(--border-light);
  background-color: var(--bg-secondary-light);
  overflow-x: auto;
  scrollbar-width: none;
}

.emoji-categories::-webkit-scrollbar {
  display: none;
}

.category-button {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  cursor: pointer;
  font-size: 16px;
  opacity: 0.7;
  transition: all 0.2s ease;
}

.category-button:hover {
  background-color: var(--bg-hover-light);
  opacity: 1;
}

.category-button.active {
  background-color: var(--bg-hover-light);
  opacity: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.no-results {
  grid-column: 1 / -1;
  padding: 1rem;
  text-align: center;
  color: var(--text-secondary-light);
  font-size: 14px;
}

@keyframes scaleIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .emoji-picker {
    background-color: var(--bg-primary-dark);
    border-color: var(--border-dark);
    box-shadow: var(--shadow-dark);
  }
  
  .emoji-picker-header {
    border-color: var(--border-dark);
  }
  
  .emoji-search {
    background-color: var(--bg-secondary-dark);
    color: var(--text-primary-dark);
    border-color: var(--border-dark);
  }
  
  .emoji-button:hover {
    background-color: var(--bg-hover-dark);
  }
  
  .emoji-categories {
    background-color: var(--bg-secondary-dark);
    border-color: var(--border-dark);
  }
  
  .category-button:hover,
  .category-button.active {
    background-color: var(--bg-hover-dark);
  }
  
  .no-results {
    color: var(--text-secondary-dark);
  }
}

/* Mobile Responsive Enhancements */
@media (max-width: 768px) {
  .emoji-picker {
    width: 100%;
    left: 0;
    bottom: 100%;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .emoji-grid {
    grid-template-columns: repeat(7, 1fr);
  }
  
  .emoji-button {
    width: 36px;
    height: 36px;
  }
  
  .category-button {
    width: 36px;
    height: 36px;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .emoji-picker {
    height: 320px;
  }
  
  .emoji-grid {
    grid-template-columns: repeat(6, 1fr);
    gap: 2px;
  }
  
  .emoji-button {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
  
  .category-button {
    width: 32px;
    height: 32px;
    font-size: 14px;
    border-radius: 6px;
  }
  
  .emoji-search {
    padding: 6px 10px;
    font-size: 12px;
  }
}

/* Landscape orientation for mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .emoji-picker {
    height: 220px;
  }
  
  .emoji-grid {
    grid-template-columns: repeat(10, 1fr);
    padding: 8px;
  }
  
  .emoji-picker-header {
    padding: 8px;
  }
  
  .emoji-categories {
    padding: 4px;
  }
}

/* Touch-friendly improvements */
@media (pointer: coarse) {
  .emoji-button, 
  .category-button {
    min-height: 44px;
    min-width: 44px;
  }
  
  .emoji-search {
    height: 36px;
  }
}

/* Fix for iOS devices - prevent zoom on input focus */
@supports (-webkit-touch-callout: none) {
  .emoji-search {
    font-size: 16px;
  }
} 