/* ChatFrame.css */

/* Base Variables */
:root {
  /* Light mode colors */
  --bg-primary-light: #ffffff;
  --bg-secondary-light: #f8f9fa;
  --text-primary-light: #212529;
  --text-secondary-light: #6c757d;
  --border-light: #e1e4e8;
  --message-sent-light: #3b82f6;
  --message-received-light: #f1f3f5;
  --date-separator-light: rgba(0, 0, 0, 0.05);
  --primary-color: #4a7dff;
  --primary-color-dark: #2563eb;
  --bg-hover-light: #eef2ff;
  --nav-height-desktop: 80px;
  --nav-height-mobile: 60px;
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --message-radius: 18px;
  --accent-color: #ff7e5f;
  --success-color: #2ecc71;
  --warning-color: #f59e0b;
  --error-color: #ef4444;
  
  /* Dark mode colors */
  --bg-primary-dark: #1a1d21;
  --bg-secondary-dark: #2d3035;
  --text-primary-dark: #f8f9fa;
  --text-secondary-dark: #adb5bd;
  --border-dark: #40454e;
  --message-sent-dark: #3b82f6;
  --message-received-dark: #374151;
  --date-separator-dark: rgba(255, 255, 255, 0.1);
  --bg-hover-dark: #3a3f48;
  --message-self: #4a7dff;
  --message-other: #f1f3f5;
  --message-self-dark: #3a65cc;
  --message-other-dark: #2d3035;
  --shadow-light: 0 4px 12px rgba(0, 0, 0, 0.08);
  --shadow-dark: 0 4px 12px rgba(0, 0, 0, 0.25);
  --message-sent-bg: #026440;
  --message-sent-text: #ffffff;
  --message-received-bg: #f3f4f6;
  --message-received-text: #1f2937;
  --reaction-bg: rgba(255, 255, 255, 0.9);
  --reaction-bg-hover: rgba(114, 218, 55, 0.2);
  --reaction-border: rgba(0, 0, 0, 0.1);
  --reaction-text: #4b5563;
  --typing-bg: #f3f4f6;
  --typing-dot: #4ca819;
  --chat-bg: #ffffff;
  --header-bg: #ffffff;
  --scroll-button-bg: #026440;
  --secondary-color: #72da37;
}

/* Overall chat frame styling */
.chat-frame {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  background: var(--chat-bg);
  position: relative;
  overflow: hidden;
}

.chat-container {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}

.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: var(--chat-bg);
}

/* Header Styling */
.chat-header {
  height: 70px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-light);
  background: var(--bg-secondary-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.chat-header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.chat-header-left h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  background: linear-gradient(90deg, var(--primary-color), var(--primary-color-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.status-indicator {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: 500;
}

.status-indicator.online {
  color: var(--success-color);
  background: rgba(16, 185, 129, 0.1);
}

.status-indicator.online::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--success-color);
  border-radius: 50%;
  margin-right: 6px;
}

.status-indicator.offline {
  color: var(--error-color);
  background: rgba(239, 68, 68, 0.1);
}

.status-indicator.offline::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--error-color);
  border-radius: 50%;
  margin-right: 6px;
}

.chat-header-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: var(--text-secondary-light);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.icon-button:hover {
  background: var(--bg-hover-light);
  color: var(--primary-color);
}

.icon-button.active {
  background: var(--bg-hover-light);
  color: var(--primary-color);
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--error-color);
  color: white;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--bg-primary-light);
}

/* Message Area Styling */
.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  background-image: linear-gradient(rgba(249, 250, 251, 0.8), rgba(249, 250, 251, 0.8)), 
                    url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%232563eb' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.message-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
}

/* Loading Container */
.loading-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: var(--text-secondary);
  height: 100%;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  position: relative;
  margin-bottom: 1rem;
  filter: drop-shadow(0 0 10px rgba(114, 218, 55, 0.25));
}

.loading-spinner:before,
.loading-spinner:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.12));
}

.loading-spinner:before {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  animation-name: pulse-shadow;
  box-shadow: 0 0 15px rgba(2, 100, 64, 0.3);
}

.loading-spinner:after {
  width: 80%;
  height: 80%;
  background-color: var(--bg-primary);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: pulse-core;
}

@keyframes pulse-shadow {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
}

@keyframes pulse-core {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.92);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.loading-container p {
  font-size: 1rem;
  color: var(--text-secondary);
  font-weight: 500;
  margin-top: 0.5rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  letter-spacing: 0.2px;
}

.loading-container p:after {
  content: "...";
  position: absolute;
  width: 24px;
  text-align: left;
  animation: dots 1.5s infinite steps(4);
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: var(--text-secondary);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 var(--text-secondary),
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 var(--text-secondary),
      .5em 0 0 var(--text-secondary);
  }
}

.loading-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background: linear-gradient(90deg, var(--secondary-color), var(--primary-color));
  border-radius: 0 4px 4px 0;
  box-shadow: 0 0 10px rgba(114, 218, 55, 0.5);
  z-index: 5;
  transition: width 0.3s ease-out;
}

.loading-hint {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-top: 2rem;
  background: rgba(2, 100, 64, 0.05);
  padding: 0.75rem 1.25rem;
  border-radius: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  animation: breathe 3s infinite ease-in-out;
  border: 1px solid rgba(114, 218, 55, 0.15);
}

.loading-hint svg {
  color: var(--primary-color);
  animation: bounce 2s infinite ease;
}

@keyframes breathe {
  0%, 100% {
    opacity: 0.8;
    transform: scale(0.98);
  }
  50% {
    opacity: 1;
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(2, 100, 64, 0.12);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}

/* Date Separator */
.date-separator {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  width: 100%;
}

.date-line {
  flex: 1;
  height: 1px;
  background: var(--date-separator-light);
}

.date-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
  padding: 4px 12px;
  background: var(--bg-secondary);
  border-radius: 12px;
  white-space: nowrap;
}

/* Message Styling */
.message-item {
  position: relative;
  margin-bottom: 0.75rem;
  max-width: 70%;
}

.message-item.received {
  padding-left: 0.5rem;
  align-self: flex-start;
}

.message-item.sent {
  padding-right: 0.5rem;
  align-self: flex-end;
}

.message-bubble {
  position: relative;
  padding: 0.75rem 1rem;
  border-radius: var(--message-radius);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  overflow-wrap: break-word;
  word-break: break-word;
  transition: all 0.2s ease;
}

.message-item.sent .message-bubble {
  background-color: var(--message-sent-bg);
  color: white;
  border-bottom-right-radius: 4px;
}

.message-item.received .message-bubble {
  background-color: var(--message-received-bg);
  color: var(--message-received-text);
  border-bottom-left-radius: 4px;
}

.message-bubble:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
}

.message-text {
  font-size: 0.95rem;
  line-height: 1.5;
}

.message-item.sent .message-text a {
  color: #fff;
  text-decoration: underline;
}

.message-item.received .message-text a {
  color: var(--primary-color);
  text-decoration: underline;
}

.message-meta {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  font-size: 0.75rem;
  opacity: 0.7;
}

.message-item.sent .message-meta {
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.9);
}

.message-item.received .message-meta {
  color: var(--text-secondary);
}

.message-time {
  margin-right: 0.3rem;
}

.message-status {
  display: flex;
  align-items: center;
}

.status-icon {
  font-size: 0.8rem;
}

.message-emoji {
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0.1rem;
  vertical-align: middle;
}

.message-user {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}

.user-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.user-name {
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--text-secondary);
}

/* Typing indicator */
.typing-indicator {
  margin: 0.5rem 0;
  display: flex;
  align-items: flex-start;
  max-width: 50%;
}

.typing-bubble {
  display: flex;
  align-items: center;
  background-color: var(--typing-bg);
  padding: 0.5rem 1rem;
  border-radius: var(--message-radius);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  animation: fadeIn 0.3s ease;
}

.typing-dots {
  display: flex;
  margin-right: 0.5rem;
}

.typing-dots span {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--typing-dot);
  border-radius: 50%;
  margin: 0 0.1rem;
  opacity: 0.7;
}

.typing-dots span:nth-child(1) {
  animation: typingDot 1.4s infinite 0.2s;
}

.typing-dots span:nth-child(2) {
  animation: typingDot 1.4s infinite 0.4s;
}

.typing-dots span:nth-child(3) {
  animation: typingDot 1.4s infinite 0.6s;
}

.typing-text {
  font-size: 0.85rem;
  color: var(--text-secondary);
}

@keyframes typingDot {
  0%, 60%, 100% { 
    transform: translateY(0);
  }
  30% { 
    transform: translateY(-4px); 
  }
}

/* Message reactions */
.message-reaction-button {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  background: var(--bg-primary);
  border: 1px solid var(--border-light);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  font-size: 0.9rem;
  z-index: 1;
}

.message-item.received .message-reaction-button {
  right: auto;
  left: -0.5rem;
}

.message-bubble:hover .message-reaction-button {
  opacity: 1;
}

.reaction-menu {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--reaction-bg);
  border-radius: 1.5rem;
  padding: 0.25rem;
  display: flex;
  gap: 0.25rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--reaction-border);
  margin-bottom: 0.5rem;
  z-index: 10;
  animation: scaleIn 0.2s ease forwards;
}

.reaction-button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.15s ease;
  font-size: 1.1rem;
}

.reaction-button:hover {
  background-color: var(--reaction-bg-hover);
  transform: scale(1.1);
}

.message-reactions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.35rem;
  margin-top: 0.35rem;
}

.reaction-badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: var(--bg-secondary);
  border-radius: 1rem;
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
  color: var(--text-secondary);
  border: 1px solid var(--border-light);
  transition: all 0.15s ease;
}

.reaction-badge.user-reacted {
  background-color: rgba(74, 125, 255, 0.1);
  border-color: rgba(74, 125, 255, 0.3);
  color: var(--primary-color);
}

.reaction-badge:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.reaction-icon {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
}

.reaction-count {
  font-size: 0.75rem;
  font-weight: 500;
}

@keyframes scaleIn {
  from { 
    opacity: 0; 
    transform: translateX(-50%) scale(0.8); 
  }
  to { 
    opacity: 1; 
    transform: translateX(-50%) scale(1); 
  }
}

/* Scroll Button */
.scroll-top-button {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: var(--scroll-button-bg);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10;
}

.scroll-top-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.scroll-top-button svg {
  font-size: 1.25rem;
}

.unread-count {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: var(--error-color);
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Offline Indicator */
.offline-indicator {
  padding: 0.75rem;
  background: rgba(239, 68, 68, 0.1);
  color: var(--error-color);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  border-top: 1px solid rgba(239, 68, 68, 0.2);
}

/* Error Container */
.error-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  text-align: center;
}

.error-container p {
  color: var(--error-color);
  font-weight: 500;
}

.error-container button {
  padding: 0.5rem 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.error-container button:hover {
  background: var(--primary-color-dark);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Mobile and responsive design optimizations */
@media (max-width: 768px) {
  .chat-messages {
    padding: 0.75rem;
  }

  .message-item {
    padding: 0.35rem 0.5rem;
  }

  .message-bubble {
    padding: 0.5rem 0.75rem;
    max-width: 85%;
  }

  .message-text {
    font-size: 0.9rem;
  }

  .message-reaction-button {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.8rem;
  }

  .reaction-menu {
    padding: 0.35rem;
    gap: 0.25rem;
  }

  .reaction-button {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.9rem;
  }

  .typing-indicator {
    padding: 0.35rem 0.75rem;
    margin: 0.5rem 0;
  }

  .scroll-top-button {
    bottom: 1rem;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .message-user {
    margin-bottom: 0.25rem;
  }

  .user-avatar {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.7rem;
  }

  .user-name {
    font-size: 0.8rem;
  }

  .message-meta {
    margin-top: 0.25rem;
  }

  .message-time {
    font-size: 0.65rem;
  }

  .date-separator {
    margin: 0.5rem 0;
  }

  .date-text {
    font-size: 0.75rem;
    padding: 0 0.5rem;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .chat-messages {
    padding: 0.5rem;
  }
  
  .message-item {
    padding: 0.25rem 0.35rem;
  }

  .reaction-menu {
    width: 180px;
    padding: 0.25rem;
  }

  .message-item.received .reaction-menu {
    right: auto;
    left: 0;
  }

  .message-item.sent .reaction-menu {
    left: auto;
    right: 0;
  }

  .message-bubble {
    padding: 0.4rem 0.6rem;
    border-radius: 12px;
  }

  .message-text {
    font-size: 0.85rem;
  }

  .message-meta {
    margin-top: 0.2rem;
  }

  .message-time, .message-status {
    font-size: 0.6rem;
  }

  .typing-indicator {
    margin: 0.35rem 0;
  }

  .typing-bubble {
    padding: 0.35rem 0.5rem;
  }

  .typing-text {
    font-size: 0.7rem;
  }

  .scroll-top-button {
    width: 2rem;
    height: 2rem;
    bottom: 0.75rem;
    right: 0.75rem;
  }

  .unread-count {
    width: 1rem;
    height: 1rem;
    font-size: 0.6rem;
  }

  .date-text {
    font-size: 0.7rem;
  }

  .message-reactions {
    margin-top: 0.2rem;
    gap: 0.2rem;
  }

  .reaction-badge {
    font-size: 0.7rem;
    padding: 0.2rem 0.3rem;
    border-radius: 10px;
  }
}

/* Touch-friendly targets */
@media (pointer: coarse) {
  .reaction-button,
  .message-reaction-button,
  .scroll-top-button {
    min-width: 44px;
    min-height: 44px;
  }
}

